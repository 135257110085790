import React from 'react'
import {Link } from "react-router-dom";
import drsankarkumarroy from "../images/orthepaedics-krishnagar.jpeg";

import ScrollToTop from "react-scroll-to-top"
import Navbar from '../inc/Navbar';
import Sliderortho from '../inc/Sliderortho';


function Doctor() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br />
     
         <ScrollToTop smooth top="100" color="Darkblue"/>
         <div className='spinesurgerykrishnanagar'>
                <br />
                <div className="album py-1">

                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/about" >About  Us</Link></li>
                                <li className="breadcrumb-item"><Link to="/Contactus" >Contact  Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Doctor</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <br/>
     <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div class="card mb-3" >
                                <div className="row g-0">
                                    <div className="col-md-5">
                                        
                                        <img src={drsankarkumarroy} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 style={{color:"Darkblue"}} className="card-title mb-2"><b>DR. SANKAR KUMAR ROY <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg></b></h4>
                                            <p style={{color:"grey"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg> Consultant Orthopaedic Surgeon</p>

                                            <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg> Expert in Ortho related traumatic injury, Arthroplasty etc</p>

<p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg> Sai Medical - 6, B.L. Chatterjee Road, Krishnanagar, 741101</p>
                                            <h5 style={{color:"maroon"}}  className="card-text mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg> Reg. No. 63872 (WBMC)</h5>
<a href='tel: 8759503787' className="callbook" type="submit">Call Us Now</a>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="col-md-3 mt-0">

                            {/* <Others /> */}
                            <div class="sticky-top" >
                                <div class="card">
                                    <Sliderortho/>
                                    <div class="card-body">
                                    <button className='callbooks button1 mb-2'><small>Ortho related traumatic injury</small></button>
                      
                                        <div className="d-flex justify-content-between align-items-center mb-2">

                                        <button className='callbooks button1'><small>Spine Surgery</small></button>
                                        <button className='callbooks button1'><small>Arthroplasty</small></button>

                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br/>
    </div>
  )
}

export default Doctor