import React from 'react';
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top"
import { Link } from "react-router-dom";

import "./Contactus.css";
import Googlemap from './Googlemap';




function Contactus() {
  return (

    <>
      <Navbar />
      <br />
      <br />
      <br />
   

      <ScrollToTop smooth top="100" color="Gray" />
      <div className="bestorthopedicinkrishnagar">
        <br />
        <center>
          <h1 className='mt-3'><b>DR. SANKAR KUMAR ROY <svg xmlns="http://www.w3.org/2000/svg" style={{color:"Darkblue"}} width="34" height="34" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg></b></h1>
          <h3>CONTACT US</h3>
        </center>
        <div className="album py-1">

          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/"> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/about" >About Us</Link></li>
                <li className="breadcrumb-item"><Link to="/Doctor" >Doctor</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>

        </div>
      </div>
      <div className="p-4 mb-2 bg-white text-dark">




        <div className="album py-0">

          <div className="container">

            <div className="row justify-content-center">

              <div className="col-md-5" >


                <h6 className="card-text">Clinics Address</h6>
                <p className="card-text mb-2">SAI MEDICAL</p>
                <p style={{ color: "maroon" }} className="card-text mb-1">6, B.L Chatterjee Road, Talpukur, Krishnanagar</p>
                <p style={{ color: "maroon" }} className="card-text mb-2">Pin-741101, Dist- Nadia </p>
                <p style={{ color: "maroon" }} className="card-text">West Bengal | India</p>

                <br />


                <p className="card-text"> <br />Copyright © Dr. Sankar Kumar Roy</p>



                <div className="d-flex justify-content-between align-items-center">


                </div>

              </div>
              <div className="col-md-4 mt-4" >

                <h6 style={{ color: "Gray" }} className="card-text mb-3">Contact Details</h6>
                <a style={{ color: "maroon" }} href="tel: +91 8759503787" className="mb-4">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 8759503787</a>
                <br />
                <br/>
                <a style={{ color: "maroon" }} href="tel: +91 8972722847" className="mb-4">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 8972722847</a>

                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
              <div className="col-md-3 mt-4" >
                <h6 style={{ color: "Gray" }} className="card-text " > <b>Email Contact</b></h6>


                <a href='mailto: sankarkrroy@gmail.com'>sankarkrroy@gmail.com</a>


                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
            </div>
          </div>
        </div>




    <Googlemap/>

      </div>



    </>

  );
}

export default Contactus;