import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";

import othepaedickrishnagar from "../images/orthepaedics-krishnagar.jpeg"
import "./About.css";


function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <div className='spinesurgerykrishnanagar'>
                <br />
                <div className="album py-1">

                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"> Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/Contactus" >Contact  Us</Link></li>
                                <li className="breadcrumb-item"><Link to="/Doctor" >Doctor</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>



            <div className='album py-3'>
                <div className="container">

                    <div className="row">

                        <div className="col-md-6 mt-2">
                            <div className='no1droctorkrishnagar'>
                            <div className='hoverimage1'>
                                <img src={othepaedickrishnagar} className="card-img-top image2" alt="othepaedic-krishnagar" />
                                <div className="overlay1">Dr. Sankar Kumar Roy <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg></div>
                                </div>
                            </div>
                            <div className="card-body">



                            </div>
                        </div>
                        <div className="col-md-6 mt-3">


                            <div className="card-body">
                                <h1 className="card-title"><small>DR. SANKAR KUMAR ROY</small> <svg xmlns="http://www.w3.org/2000/svg" style={{color:"Darkblue"}} width="30" height="30" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg></h1>
                                <p className="card-text mb-0">Consultant Orthopaedic Surgeon</p>
                                <p className="card-text mb-4"> M.B.B.S (Cal), M.S Ortho (WBUHS)</p>
                                <p className="card-text">Best Orthopaedic surgeon in West Bengal, Expert in Ortho related traumatic injury, Arthroplasty, Spine Surgery etc.   </p>
                                <p style={{color:"darkBlue"}} className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{color:"darkBlue"}} className="bi bi-check-all" viewBox="0 0 16 16">
  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
</svg>Doctor's Name</b> Dr. Sankar Kumar Roy</p>
                                <p style={{color:"darkBlue"}} className="card-text mb-2"><b><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{color:"darkBlue"}} className="bi bi-check-all" viewBox="0 0 16 16">
  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
</svg> Registration No</b> 63872 (WBMC)</p>

<p style={{color:"darkBlue"}} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{color:"darkBlue"}} className="bi bi-check-all" viewBox="0 0 16 16">
  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
</svg>M.B.B.S (Cal), M.S Ortho (WBUHS)</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Aboutus