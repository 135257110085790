import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Doctor from './components/pages/Doctor';
import Aboutus from './components/pages/Aboutus';


function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contactus" element={<Contactus/>}/>
      <Route axact path="/Doctor" element={<Doctor/>}/>
      <Route axact path="/about" element={<Aboutus/>}/>
      
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
