import React from 'react'

function Googlemap() {
    return (
        <div>
            <div className="album py-2">

                <div className="container">
                    <div className="row">
                        <iframe className="iframe-fluid mb-4" height="440" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=SAI%20MEDICAL,%20CF4V+HJQ,%20BL%20Chatterjee%20Rd,%20Krishnanagar,%20West%20Bengal%20741101%20+(SAI%20MEDICAL,%20CF4V+HJQ,%20BL%20Chatterjee%20Rd,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        <iframe className="iframe-fluid" src="https://www.google.com/maps/embed?pb=!4v1706293362105!6m8!1m7!1sV_GtL4Pn7CfEHQr8NOSIlg!2m2!1d23.40643930294129!2d88.49423165217928!3f307.4633777291637!4f1.0612448264784717!5f1.5702993196558594"  height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
          
        </div>
    )
}

export default Googlemap