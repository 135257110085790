import React from 'react'
import xrayimage  from "../images/beforeimage.jpeg";
import Xrayorthokrishnagar from "../images/orthopaedics-leg.jpeg"
function Sliderortho() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={xrayimage} className="d-block w-100" alt="xrayimage"/>
    </div>
   
    <div className="carousel-item">
      <img src={Xrayorthokrishnagar} className="d-block w-100" alt="Xrayorthokrishnagar"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderortho